import React, { useEffect } from "react";
import { motion } from "framer-motion";
import Image from "../Assets/newUniteLogo.jpeg";
import BackgroundImage from "../Assets/greenBGHome.jpg";

const staggerContainer = (staggerChildren, delayChildren) => ({
  hidden: {},
  show: {
    transition: {
      staggerChildren,
      delayChildren,
    },
  },
});

const fadeIn = (direction, type, delay, duration) => ({
  hidden: {
    x: direction === "left" ? 100 : direction === "right" ? -100 : 0,
    y: direction === "up" ? 100 : direction === "down" ? -100 : 0,
    opacity: 0,
  },
  show: {
    x: 0,
    y: 0,
    opacity: 1,
    transition: {
      type,
      delay,
      duration,
      ease: "easeOut",
    },
  },
});

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section
      className={`min-h-screen flex justify-center items-center bg-slate-900`}
      style={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundBlendMode: "lighten",
      }}
    >
      <div className={`  w-full px-6`}>
        <motion.div
          variants={staggerContainer(0.1, 0)}
          initial="hidden"
          animate="show"
          className={` md:p-20 md:px-0 grid grid-cols-1 place-items-center sm:grid-cols-2 justify-between items-center gap-8`}
        >
          {/* Text Section */}
          <motion.div
            variants={fadeIn("left", "tween", 0.2, 1)}
            className="md:order-1 flex flex-col justify-center items-start md:pl-10"
          >
            <h1 className="text-5xl md:text-6xl font-bold text-white mb-8">
              Welcome to U.N.I.T.E!
            </h1>
            <p className="text-lg md:text-xl text-white mb-8">
              This application is designed to educate students on the risk
              factors of campus shootings.
            </p>
            <div className="flex">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300 mx-3 ml-0"
              >
                <a
                  href="https://www.facebook.com/people/UNITEwsu/61557847684984/?mibextid=ZbWKwL"
                  target="_blank"
                >
                  {" "}
                  <i className="fab fa-facebook"></i>
                </a>
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300 mx-3"
              >
                <a href="https://www.twitter.com/UNITEWSU" target="_blank">
                  {" "}
                  <i className="fab fa-twitter"></i>
                </a>
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300 mx-3"
              >
                <a href="https://www.instagram.com/unite_wsu/" target="_blank">
                  {" "}
                  <i className="fab fa-instagram"></i>
                </a>
              </motion.button>
            </div>
          </motion.div>

          {/* Image Section */}
          <motion.div
            variants={fadeIn("right", "tween", 0.2, 1)}
            className="md:order-2 hidden md:block"
          >
            <motion.img
              src={Image}
              alt="UNITE logo"
              className="w-[200px] h-[200px] md:w-[600px] md:h-[600px] object-contain rounded-s-full"
            />
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default HomePage;
