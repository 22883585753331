import React, { useEffect } from "react";
import { motion } from "framer-motion";
import groupPhotoBanner from "../Assets/newBanner.webp";

function Resources() {
  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    show: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
  };

  const stagger = {
    show: { transition: { staggerChildren: 0.2 } },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col items-center pb-10">
      <motion.div
        className="w-full bg-cover bg-center mb-4 rounded-lg overflow-hidden shadow-lg"
        style={{
          backgroundImage: `url(${groupPhotoBanner})`,
          backgroundSize: "cover",
          height: "300px",
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6, delay: 0.2 }}
      ></motion.div>
      <h1 className="text-4xl font-bold mt-8 mb-6 text-red-500 underline">
        Resources
      </h1>
      <motion.div
        className="grid grid-cols-1 sm:grid-cols-2 gap-6 justify-evenly sm:mx-5"
        variants={stagger}
        initial="hidden"
        animate="show"
      >
        <motion.div
          className="m-3 bg-slate-200 rounded-lg overflow-hidden shadow-lg"
          variants={fadeInUp}
        >
          <div className="p-6">
            <h2 className="text-2xl font-semibold mb-2 text-gray-600">WSU:</h2>
            <p className="text-lg mb-4 text-gray-600">
              <i className="fa-solid fa-university text-blue-600 mr-2"></i>
              <strong> Wright State University Police: </strong>
              <a href="tel:1-855-353-3783" className="text-blue-600 underline">
                1-855-353-3783
              </a>{" "}
              <br />
              Wright State Counseling and Wellness <br />
              <i className="fa-solid fa-hand-holding-hand mr-2 text-green-600"></i>
              <strong> Raider Cares (24 hour-line): </strong>
              <a href="tel:937-775-4567" className="text-blue-600 underline">
                937-775-4567
              </a>
            </p>
          </div>
        </motion.div>
        <motion.div
          className="m-3 bg-slate-200 rounded-lg overflow-hidden shadow-lg"
          variants={fadeInUp}
        >
          <div className="p-6">
            <h2 className="text-2xl font-semibold mb-2 text-gray-600">
              Local:
            </h2>
            <p className="text-lg mb-4 text-gray-600">
              <i className="fa-solid fa-building text-blue-600 mr-2"></i>
              <strong> Fairborn Police Department: </strong>
              <a href="tel:937-754-3000" className="text-blue-600 underline">
                937-754-3000
              </a>{" "}
              <br />
              <i className="fa-solid fa-exclamation-triangle text-yellow-600 mr-2"></i>
              <strong>Dayton Police Department</strong> <br />
              Report Suspicious Activity to Ohio Homeland Security
              <br />
              (1-877-OHS-INTEL), or 911 if an emergency or active threat
            </p>
          </div>
        </motion.div>
        <motion.div
          className="m-3 bg-slate-200 rounded-lg overflow-hidden shadow-lg"
          variants={fadeInUp}
        >
          <div className="p-6">
            <h2 className="text-2xl font-semibold mb-2 text-gray-600">
              National:
            </h2>
            <p className="text-lg mb-4 text-gray-600">
              <i className="fa-solid fa-hands-helping text-blue-600 mr-2"></i>
              <strong>Parents for Peace:</strong> A Non-profit org dedicated to
              preventing extremism and violence: Call 24/7 helpline at{" "}
              <a href="tel:844-99-PEACE" className="text-blue-600 underline">
                844-497-3223
              </a>{" "}
              <br />
              <p className="font-semibold">
                PEACE Centers for Disease Control and Prevention-
              </p>{" "}
              <a href="/riskfactors" className="text-blue-600 underline">
                read more about risk factors here
              </a>
              <br />
              <i className="fa-solid fa-phone text-blue-600 mr-2"></i>
              <strong>National Suicide Prevention Lifeline:</strong>{" "}
              <a href="tel:800-273-8255" className="text-blue-600 underline">
                800-273-8255
              </a>
              <br />
              <i className="fa-solid fa-person-dress-burst text-red-400 mr-1"></i>
              <strong>Violence Interrupter Programs</strong> : These help those
              most at risk get connected to social programs.
              <br />
              <div className="pl-5">
                <ul className="text-lg list-disc pl-6">
                  <li className="font-semibold">
                    Save our Streets (Brooklyn, NY)
                  </li>{" "}
                  <li className="font-semibold">
                    Save our Community (Toledo, OH)
                  </li>{" "}
                  <li className="font-semibold">
                    Community Initiative to Reduce Gun Violence (Dayton, OH)
                  </li>
                </ul>
              </div>
            </p>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
}

export default Resources;
