import React from "react";
import percentStats from "../Assets/percentStats.webp";
import groupPhotoBanner from "../Assets/newBanner.webp";
import ChartImage from "../Assets/chartStats.png";
import metricsStats from "../Assets/metricStats.webp";
import { motion } from "framer-motion";
import { useEffect } from "react";
function Statistics() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const easing = [0.6, -0.05, 0.01, 0.99];
  const stagger = {
    animate: {
      transition: {
        delayChildren: 0.8,
        staggerChildren: 0.4,
        staggerDirection: 1,
      },
    },
  };

  const fadeInUp = {
    initial: {
      y: -20,
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: easing,
      },
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: easing,
      },
    },
  };

  return (
    <div className="flex flex-col justify-center items-center bg-gray-100 z-10">
      <motion.div
        className="w-full bg-cover bg-center  mb-4 "
        style={{
          backgroundImage: `url(${groupPhotoBanner})`,
          backgroundSize: "cover",
          height: "300px",
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      ></motion.div>
      <motion.div
        variants={stagger}
        className="max-w-4xl p-8 bg-white rounded-lg shadow-lg"
      >
        <h1 className="text-4xl font-bold text-center text-red-500 mb-8 underline">
          Statistics on Shootings
        </h1>
        <motion.div
          variants={fadeInUp}
          initial="initial"
          whileInView={"animate"}
          viewport={{ once: false, amount: 0.1 }}
          className="mb-8"
        >
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            Wright State Statistics:
          </h2>
          <ul className="list-disc list-outside p-5">
            <li className="text-lg text-gray-700 mb-2">
              Unfortunately, even within our own school, there have been several
              instances of active shootings on and around campus.
            </li>
            <li className="text-lg text-gray-700 mb-2">
              Even in just this year, there was an active shooter false alarm
              that left many students on the Dayton Campus with lasting PTSD.
            </li>
            <li className="text-lg text-gray-700 mb-2">
              In another instance, there was a person shot just across the
              street from campus. The American mass shooting epidemic finds its
              way into all communities.
            </li>
          </ul>
        </motion.div>

        <motion.div
          variants={fadeInUp}
          initial="initial"
          whileInView={"animate"}
          viewport={{ once: false, amount: 0.1 }}
          className="mb-8"
        >
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            Shooting Statistics:
          </h2>
          <ul className="list-disc list-outside p-5">
            <li className="text-lg text-gray-700 mb-2">
              Since 2015, over 19,000 people have been shot and wounded or
              killed in a mass shooting. (Everytown)
            </li>
            <li className="text-lg text-gray-700 mb-2">
              There have been 13 mass shootings on college campuses since 1966
              where at least three people were killed.
            </li>
            <li className="text-lg text-gray-700 mb-2">
              Gunfire on college campuses has killed 91 people in total and
              injured another 240 since 2013.
            </li>
            <li className="text-lg text-gray-700 mb-2">
              Campus shooters usually have prior connections to the campuses
              they target.
            </li>
          </ul>
        </motion.div>

        <div className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            Preventative Statistics:
          </h2>
          <motion.div
            variants={fadeInUp}
            initial="initial"
            whileInView={"animate"}
            viewport={{ once: false, amount: 0.1 }}
            className="grid grid-cols-1 sm:grid-cols-2 mb-4 "
          >
            <img
              src={percentStats}
              alt="Statistics"
              className="rounded-lg h-40 w-96"
            />
            <img
              src={metricsStats}
              alt="Statistics"
              className="rounded-lg h-40 w-96"
            />
          </motion.div>
          <motion.ul
            variants={fadeInUp}
            initial="initial"
            whileInView={"animate"}
            viewport={{ once: false, amount: 0.1 }}
            className="list-disc list-outside p-5"
          >
            <li className="text-lg text-gray-700 mb-2">
              According to the Sandy Hook Promise, almost all school mass
              shootings leave some sort of sign.
            </li>
            <li className="text-lg text-gray-700 mb-2">
              Whether this be through words, messages, or concerning images on
              social media, in even more than 75% of cases, there was some sort
              of warning prior.
            </li>
            <li className="text-lg text-gray-700 mb-2">
              93% of shooters plan their attack well in advance, as well.
            </li>
            <li className="text-lg text-gray-700 mb-2">
              In another shocking statistic, in 80% of cases, at least one other
              person had knowledge of the perpetrator's plan but failed to
              report it.
            </li>
          </motion.ul>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            Chart of Statistics:
          </h2>
          <div className="flex justify-center mb-4">
            <img
              src={ChartImage}
              alt="Chart showing statistics data"
              className="rounded-lg h-auto max-w-full"
            />
          </div>
          <p className="text-lg text-center text-gray-700">
            This chart illustrates the trends in shooting statistics over the
            past decade.
          </p>
        </div>
      </motion.div>
    </div>
  );
}

export default Statistics;
