import React, { useEffect } from "react";
import { motion } from "framer-motion";
import groupPhotoBanner from "../Assets/newBanner.webp";

function RiskFactors() {
  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    show: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
  };

  const stagger = {
    show: { transition: { staggerChildren: 0.2 } },
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="flex flex-col items-center pb-10 ">
      <motion.div
        className="w-full bg-cover bg-center mb-4 rounded-lg"
        style={{
          backgroundImage: `url(${groupPhotoBanner})`,
          backgroundSize: "cover",
          height: "300px",
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6, delay: 0.2 }}
      ></motion.div>
      <h1 className="text-4xl font-bold mt-8 mb-6 text-red-500 underline">
        Risk Factors
      </h1>
      <motion.div
        className="max-w-6xl bg-gray-100 rounded-lg overflow-hidden shadow-sm p-6 sm:px-8"
        variants={stagger}
        initial="hidden"
        animate="show"
      >
        <motion.p className="text-lg mb-4 text-justify" variants={fadeInUp}>
          It is important to note that just because someone may exhibit risk
          factors, it does not always mean violence will occur. The following
          risk factors are just consistent with past mass shooters, not everyone
          who displays these factors are going to commit acts of domestic
          terrorism
        </motion.p>
        <motion.p className="text-lg mb-4 text-justify" variants={fadeInUp}>
          From 2008-2017, a study by the Secret Service found that 77 percent at
          the time, at least one person knew about a potential shooter’s
          behavior or risk factors. 100 percent of shooters displayed concerning
          behaviors, emphasizing that shootings often were preceded by warning
          signs.
        </motion.p>
        <motion.p className="text-lg m-4 font-semibold" variants={fadeInUp}>
          Some common risk factors defined by the CDC include:
        </motion.p>
        <motion.div
          className="grid sm:grid-cols-2 grid-cols-1 place-items-center gap-4"
          variants={fadeInUp}
        >
          <motion.div
            className="flex items-start bg-slate-200 rounded-lg overflow-hidden shadow-lg p-3"
            variants={fadeInUp}
          >
            <i className="fa-solid fa-user text-3xl text-blue-600 mr-4" />
            <div>
              <h2 className="text-xl font-semibold mb-2 text-blue-600">
                Individual:
              </h2>
              <motion.ul className="text-lg list-disc pl-6" variants={fadeInUp}>
                <li>Involvement with drugs and alcohol</li>
                <li>High emotional distress in situations</li>
                <li>Poor academic performance</li>
                <li>Social rejection by peers</li>
              </motion.ul>
            </div>
          </motion.div>

          <motion.div
            className="flex items-start bg-slate-200 rounded-lg overflow-hidden shadow-lg p-3"
            variants={fadeInUp}
          >
            <i className="fa-solid fa-people-roof text-3xl text-green-600 mr-4" />
            <div>
              <h2 className="text-xl font-semibold mb-2 text-green-600">
                Family:
              </h2>
              <motion.ul className="text-lg list-disc pl-6" variants={fadeInUp}>
                <li>Low parental involvement</li>
                <li>Poor monitoring and supervision of children</li>
                <li>Parental substance abuse or criminality</li>
                <li>Low parental education and income</li>
              </motion.ul>
            </div>
          </motion.div>
        </motion.div>

        <motion.p
          className="text-lg m-4 mt-8 font-semibold"
          variants={fadeInUp}
        >
          External Incidents:
        </motion.p>
        <motion.div
          className="grid sm:grid-cols-3 grid-cols-1 place-items-center gap-4 sm:mx-4"
          variants={fadeInUp}
        >
          <motion.div
            className="flex items-start bg-slate-200 rounded-lg overflow-hidden shadow-lg p-3 h-48"
            variants={fadeInUp}
          >
            <div>
              <h2 className="text-xl font-semibold mb-2 text-gray-500">
                Exposure to Violent Media
              </h2>
              <p className="text-justify">
                M-rated video games and R-rated movies often promote dangerous
                activity which can influence the person to conduct violence and
                are often inspired by depictions of violence.
              </p>
            </div>
          </motion.div>

          <motion.div
            className="flex items-start bg-slate-200 rounded-lg overflow-hidden shadow-lg p-3 h-48"
            variants={fadeInUp}
          >
            <div>
              <h2 className="text-xl font-semibold mb-2 text-gray-500">
                Previous Incidents
              </h2>
              <p className="text-justify">
                Previous shooting incidents can inspire an individual to conduct
                and plan a shooting in the future if it is well-thought and
                planned
              </p>
            </div>
          </motion.div>
          <motion.div
            className="flex items-start bg-slate-200 rounded-lg overflow-hidden shadow-lg p-3 h-48"
            variants={fadeInUp}
          >
            <div>
              <h2 className="text-xl font-semibold mb-2 text-gray-500">
                Lack of Proper Support
              </h2>
              <p className="text-justify">
                Lack of social support can prevent those from accessing proper
                resources making them more likely to commit violence.
              </p>
            </div>
          </motion.div>
        </motion.div>

        <motion.p className="text-lg mt-6" variants={fadeInUp}>
          If you see someone struggling with these things, start to look for
          other things. Something as simple as checking someone's social media
          statuses could be enough to prevent future violence.
        </motion.p>
      </motion.div>
    </div>
  );
}

export default RiskFactors;
